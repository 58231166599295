<template>
  <div class="">
    <div class="tableConTop">
      <el-row>
        <el-col :span="6" class="tableConTopLeft">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'PermConfAdmin' }"><i class="el-icon-back iconBack"></i>{{$t('i18nn_c4418aa4798740f5')}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{$t('i18nn_7b82edfeee93b9f7')}}</el-breadcrumb-item>
          </el-breadcrumb>
          <!-- <span>{{$t('i18nn_2bbc6b12fbdf9019')}}</span> -->
        </el-col>
        <el-col :span="18" class="tableConTopRig">
          <!-- <el-button type="primary" plain icon="el-icon-upload" size="small" @click="openDioalog(null)">{{$t('i18nn_ff6fd823ffab7e6b')}}</el-button>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null)">{{$t('c0246c55b9cac963')}}</el-button> -->
        </el-col>
      </el-row>
      
    </div>
    <!-- 表单提示 -->
    <tip-form-msg :formMsgDet="['支持添加一名后台管理员，设置其后台登录用户名及密码，但不可登录网站前台','管理员必须下属某个权限组，如无权限组选择请先完成“添加权限组”步骤']">
    </tip-form-msg>

    <div class="formCon"  v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')" style="width: 800px;">
    <el-form ref="form" :rules="formRules" :model="form" label-width="150px">
        <!-- <el-form-item label="用户账号：" prop="account">
          <el-input type="text" v-model="form.account" :placeholder="$t('i18nn_db7aa54d2e90e5b8')"></el-input>
        </el-form-item> -->
        <el-form-item label="登录名：" prop="userName">
          <el-input type="text" clearable :readonly="1==dialogFormStatus" v-model="form.userName" :placeholder="$t('i18nn_cf9cf589607ad707')"></el-input>
        </el-form-item>
        <!--新增-->
        <div v-if="0==dialogFormStatus">
          <el-form-item label="用户密码：" prop="password">
            <el-input clearable type="password" show-password v-model="form.password" :placeholder="$t('i18nn_ecf43f86317f199f')"></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="repeat_password">
            <el-input clearable type="password" show-password v-model="form.repeat_password" :placeholder="$t('i18nn_6a76fe48d020d59b')"></el-input>
          </el-form-item>
        </div>
        <!--编辑-->
        <div v-else>
          <el-form-item label="用户密码：">
            <el-input clearable type="password" show-password v-model="form.password" :placeholder="$t('i18nn_ecf43f86317f199f')"></el-input>
          </el-form-item>
          <el-form-item label="确认密码：">
            <el-input clearable type="password" show-password v-model="form.repeat_password" :placeholder="$t('i18nn_6a76fe48d020d59b')"></el-input>
          </el-form-item>
        </div>
				
				<el-form-item label="账号类型：" prop="userType">
				  <el-radio-group v-model="form.userType">
				    <el-radio :label="item.code" v-for="(item,index) in selectOption.sys_bck_user_type" :key="index">
							{{item.codeText}}
						</el-radio>
				  </el-radio-group>
				</el-form-item>
				

        <el-form-item label="所属权限组：" prop="roleId" v-loading="loading_load">
          <el-select filterable clearable v-model="form.roleId" :placeholder="$t('i18nn_f23e001dbc74dcba')" @change="selChange($event)">
            <el-option
              v-for="item in selectOption.premissList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="性别：" prop="sex">
          <el-radio-group v-model="form.sex">
            <el-radio :label="'1'">{{$t('i18nn_557796858a347c06')}}</el-radio>
            <el-radio :label="'2'">{{$t('i18nn_b1749374a7524a59')}}</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <!-- <el-form-item label="手机号：" prop="phone">
          <el-input type="text" v-model="form.phone" :placeholder="$t('29799f539ee6b906')"></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input type="text" v-model="form.email" :placeholder="$t('i18nn_b9ff7fc2f7dfa2c7')"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="出生日期：" prop="birthDay">
          <el-date-picker
            v-model="form.birthDay""
            type="date"
            :placeholder="$t('68efabd029c860a9')">
          </el-date-picker>
        </el-form-item> -->
        <!-- <el-form-item label="详细住址：" prop="address">
          <el-input type="text" v-model="form.address" :placeholder="$t('i18nn_15f4f186d9700760')"></el-input>
        </el-form-item>
        <el-form-item label="隶属部门：" prop="deptId">
          <el-select filterable clearable v-model="form.deptId" :placeholder="$t('i18nn_c6703669fc78ffe6')" @change="selChange($event)">
            <el-option
              v-for="item in selectOption.deptList"
              :key="item.id"
              :label="item.deptName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="职位：" prop="position">
          <el-input type="text" v-model="form.position" :placeholder="$t('i18nn_6bbdffcf9d86218c')"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="状态：" prop="enable">
          <el-switch
            v-model="form.enable"
            :active-text="$t('i18nn_889b3ef0590d9f54')"
            :inactive-text="$t('4e9fc68608c60999')">
          </el-switch>
        </el-form-item>
        <el-form-item label="备注描述：" :placeholder="$t('i18nn_4eed7667ef911a72')">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item> -->
      </el-form>
      <el-button class="subbtn" type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{$t('4ce9979bfb6576d9')}}</el-button>
    </div>

  </div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
export default {

  // name: 'HomeConfFrame',
  // //meta信息seo用
  // metaInfo: {
  //   title: '互易天下-管理后台-基础配置'
  // },
  data() {
    return {
      // isShowFormMsg: false,
      // dialogFormVisible: false,
      dialogFormStatus: 0, //0-新增，1-修改
      selectOption: {
        // deptList:[],
				sys_bck_user_type:[],
        premissList:[]
      },
			
			loading_load:false,
      //弹窗
      // dialogTableVisible: false,
      loading: false,
      form: {
        userName:"",
        password:"",
        roleId:"",
				userType:"",
        // "id": null,
        // "account": "",//账号
        // "address":"",//详细地址
        // "birthDay": "", //出生日期
        // "deptId": "", //部门id
        // "deptName": "", //部门name
        // "email": "", // email
        // "password": "", // 密码
        // // "repassword":"",// 确认密码
        // "phone": "", // 手机
        // "position": "", // 职位
        // "remark": "", //  备注
        "repeat_password": "", // 确认密码
        // "sex": '1', //  性别
        // "userName": "", //  真实姓名
        // "enable": true//  是否启用
      },
      formRules: {
        // account: [
        //   { required: true, message: this.$t('i18nn_8623ea11efc1953d'), trigger: 'blur' },
        //   // { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        // ],
        // address: [
        //   { required: true, message: '请输入详细地址', trigger: 'blur' },
        //   // { type: 'number', message: '手机号必须为数字'}
        // ],
        // birthDay: [
        //   { required: true, message: '请选择出生日期', trigger: 'change' },
        // ],
        // deptId: [
        //   { required: true, message: '请选择部门', trigger: 'change' },
        // ],
        // deptName: [
        //   { required: true, message: '请选择部门', trigger: 'change' },
        // ],
        // email: [
        //   { required: true, message: this.$t('i18nn_b9ff7fc2f7dfa2c7'), trigger: 'blur' },
        // ],
				userType: [
          { required: true, message: this.$t('i18nn_e93cb6cc8d5978ed'), trigger: 'change' },
        ],
        password: [
          { required: true, message: this.$t('i18nn_e93cb6cc8d5978ed'), trigger: 'blur' },
        ],
        // phone: [
        //   { required: true, message: this.$t('0cc9c1aa48812e61'), trigger: 'blur' },
        // ],
        // position: [
        //   { required: true, message: this.$t('i18nn_6bbdffcf9d86218c'), trigger: 'blur' },
        // ],
        // remark: [
        //   { required: true, message: this.$t('i18nn_966f3d0952773d84'), trigger: 'blur' },
        // ],
        repeat_password: [
          { required: true, message: this.$t('i18nn_97954de4658077a8'), trigger: 'blur' },
        ],
        // sex: [
        //   { required: true, message: '请输入性别', trigger: 'blur' },
        // ],
        userName: [
          { required: true, message: this.$t('i18nn_8623ea11efc1953d'), trigger: 'blur' },
        ],
        roleId: [
          { required: true, message: this.$t('i18nn_4f661d272dfc6cd3'), trigger: 'blur' },
        ],
        // enable: [
        //   { required: true, message: '请选择是否启用', trigger: 'change' },
        // ],

      },
    }
  },
	activated() {
		this.initData();
	},
    //创建时
  created() {
    // this.initData()
  },
  //编译挂载前
  mounted() {
		//数据字典
		getDicData(['sys_bck_user_type'],
			(data)=>{
				this.selectOption.sys_bck_user_type = data['sys_bck_user_type'];
		});
	},
  methods: {
    initData(){
      //部门数据
      let userId = this.$route.query.userId;
      if(userId){
        //修改
        this.dialogFormStatus = 1;
        this.getUserDetData(userId);
      } else {
        //新增
        this.dialogFormStatus = 0;
      }
      //权限组
      this.getPremissData();
      
    },
    //切换显示tip
    // toggleShowFormMsg(){
    //   this.isShowFormMsg = !this.isShowFormMsg;
    // },
    //提交信息
    submitForm(formName) {

      //密码是否一样
      if(this.form.password && this.form.repeat_password){
        if(this.form.password!==this.form.repeat_password){
          this.$alert(this.$t('i18nn_2c344876df66771f'), this.$t('cc62f4bf31d661e3'), {
              type: 'warning',
              confirmButtonText: this.$t('204ffab8a6e01870'),
            });
          return;
        }
      }
      
      this.$refs[formName].validate((valid) => {

        // if (true) {
        if (valid) {
          // alert('submit!');
          let formData = Object.assign({}, this.form);
          //浅拷贝、对象属性的合并
          // this.form = Object.assign({},form);
          if (0 === this.dialogFormStatus) {
            // this.formData.id = null;
            // formData.id = null;
            // formData.memberId = this.$store.getters.getUserInfo.buyerId;
            // formData.createBy = this.$store.getters.getUserInfo.id;
            this.postData(this.$urlConfig.HyAdminAdd, formData);
          } else {
            // formData.id = null;
            // formData.memberId = this.$store.getters.getUserInfo.buyerId;
            // formData.createBy = this.$store.getters.getUserInfo.id;
            this.postData(this.$urlConfig.HyAdminUpdate, formData);
          }

        } else {
          console.log('error submit!!');
          this.$alert('提交有误,请检查提交数据！', this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            confirmButtonText: this.$t('204ffab8a6e01870'),
          });
          return false;
        }
      });
    },
    //重置输入框
    resetForm(formName) {
      console.log(formName);
      this[formName] = {};
      // console.log(this.$refs[formName]);
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
        // this.form.remark = ""; //  备注//非必填
      } else {
        console.log('this.$refs[formName]', this.$refs[formName]);
      }
    },
    //提交信息
    postData(url, formData) {
      let _this = this;
      this.loading = true;

      // formData.enable = formData.enable ? '1' : '0';

      this.$http.post(url, formData)
        .then(({ data }) => {
          console.log(this.$t('i18nn_bc868e024b80d2e3'));
          console.log(data);
          
          this.loading = false;
          if (200 == data.code) {
            // this.dialogFormVisible = false;
            // this.getPageData();
            this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
              type: 'success',
              confirmButtonText: this.$t('204ffab8a6e01870'),
            });
            this.$router.push({name:'PermConfAdmin'});

          } else {
            // if (!data.msg) {
            //   data.msg = "提交失败,请重试";
            // }
            this.$alert(data.msg?data.msg:'提交失败,请重试', this.$t('cc62f4bf31d661e3'), {
              type: 'warning',
              confirmButtonText: this.$t('204ffab8a6e01870'),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(this.$t('i18nn_a7d2e953195a5588'));
          this.loading = false;
          this.$alert('提交失败,请重试！', this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            confirmButtonText: this.$t('204ffab8a6e01870'),
          });
        });
    },

    // 下拉框选择改变
      selChange(vId){
        console.log(arguments);
        // let obj = this.getGcNameByGcId(this.selectOption['deptList'],vId);
        // console.log(obj);
        // this['form']['deptName'] = obj.deptName;
      },
      //根据下拉框id获得对象
      getGcNameByGcId(list,id){
        // let name="";
        for (let i = list.length - 1; i >= 0; i--) {
          // list[i]
          if(list[i].id===id){
            return list[i];
          }
        }
        return {'subValue':this.$t('i18nn_6b57a5faf337b87b')};
      },
      
    // 查询部门接口
    // getDetData(keyword) {
    //   let _this = this;
    //   console.log(keyword);

    //   // this.loading_load = true;
    //   this.$http.put(this.$urlConfig.HyDeptInfo, { keyword:keyword})
    //     .then(({ data }) => {
    //       console.log("查询部门接口，请求成功");
    //       console.log(data);
    //       if (200 == data.code) {
    //         this.selectOption.deptList = data.rows;
    //         // this.$alert('恭喜,查询部门接口成功！', this.$t('cc62f4bf31d661e3'), {
    //         //   type: 'success',
    //         //   confirmButtonText: this.$t('204ffab8a6e01870'),
    //         // });
    //         // this.getPageData();
    //       } else {
    //         if (!data.msg) {
    //           data.msg = "查询部门接口失败,请重试";
    //         }
    //         // this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
    //         //   type: 'warning',
    //         //   confirmButtonText: this.$t('204ffab8a6e01870'),
    //         // });
    //         this.$message.warning(data.msg);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       console.log("查询部门接口，请求失败");
    //       // this.loading_load = false;
    //       // this.$alert('查询部门接口失败,请重试！', this.$t('cc62f4bf31d661e3'), {
    //       //   type: 'warning',
    //       //   confirmButtonText: this.$t('204ffab8a6e01870'),
    //       // });
    //       this.$message.error('查询部门接口失败,请重试！');
    //     });
    // },
    // 查询权限组接口
    getPremissData() {
      // let _this = this;
      // console.log(keyword);

      this.loading_load = true;
      this.$http.post(this.$urlConfig.HyPermissGropPage, { 
				offset:0,
				limit:1000,
			})
        .then(({ data }) => {
          console.log("查询权限组接口，请求成功");
          console.log(data);
          this.loading_load = false;
          if (200 == data.code) {
            this.selectOption.premissList = data.rows;
        
          } else {
            // if (!data.msg) {
            //   data.msg = "查询权限组接口失败,请重试";
            // }
            
            // this.$message.warning(data.msg);
            this.$message.warning(data.msg?data.msg:'查询权限组接口失败,请重试');
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading_load = false;
          console.log("查询权限组接口，请求失败");
          this.$message.error('查询权限组接口失败,请重试！');
        });
    },
   
    //查询详情接口
    getUserDetData(id) {
      // let _this = this;
      this.loading = true;
      this.$http.post(this.$urlConfig.HyAdminDetail, {
          "userId": id,
        })
        .then(({ data }) => {
          console.log("用户详情，请求成功");
          console.log(data);
          
          this.loading = false;
          if(200==data.code){
            let form = data.data;
            // form.enable = form.enable === '1' ? true : false;
            //浅拷贝、对象属性的合并
            this.form = Object.assign({}, form);
            //表格显示数据
            // this.tableData = data.rows;
            //当前数据总条数
            // this.pagination.total = parseInt(data.total);
            //当前页数
            // this.pagination.current_page = parseInt(data.current);
            //当前页条数
            // this.pagination.page_size = parseInt(data.size);
            // this.$message.success('用户详情，请求成功');
          } else {
            // if (!data.msg) {
            //   data.msg = "查询用户详情失败,请重试";
            // }
            // this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
            //   type: 'warning',
            //   confirmButtonText: this.$t('204ffab8a6e01870'),
            // });
            this.$message.warning(data.msg?data.msg:this.$t('i18nn_35da7aebbfd476d0'));
          }
          
        })
        .catch((error) => {
          console.log(error);
          console.log("用户详情，请求失败");
          this.$message.error('详情数据，请求失败！');
          this.loading = false;
        });
    },


  }
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/

</style>
